import React from "react";
import Header from "../../components/header";
import { ContactHero } from "../../components/hero";
import Footer from "../../components/footer";
import { withRouter } from "react-router-dom";
import "./index.scss";

const Contact = () => {
  return (
    <div className="container_container">
      <Header />
      <ContactHero />
      <div className="contact_cards container">
        <div className="contact_card">
          <span>
            <i className="fad fa-phone" />
          </span>
          <p>(+91) 7973342076</p>
        </div>
        <div className="contact_card">
          <span>
            <i className="fad fa-paper-plane" />
          </span>
          <p>bakhshishazadnjm@gmail.com</p>
        </div>
        <div className="contact_card">
          <span>
            <i className="fad fa-map-marked" />
          </span>
          <p>Rode, Bhagha Purana (Moga) , Punjab</p>
        </div>
      </div>
      <div className="container my-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.4352882763133!2d75.01661977163633!3d30.678026639771964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910ab9f21a7bc23%3A0xd35867dbdc4fd29b!2sRode%2C%20Punjab%20142049!5e0!3m2!1sen!2sin!4v1609492237929!5m2!1sen!2sin"
          width="100%"
          height="400"
          frameBorder="0"
          style={{ borderRadius: "10px" }}
          allowFullScreen="true"
          aria-hidden="false"
          tabIndex="0"
        />
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Contact);
