import {useEffect, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo.png"
import "./index.scss";

const Header = ({location}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { pathname } = location;
  const toogleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className="header_container">
      <div className="header_wrapper container">
        <div className="header_logo">
          <img src={Logo} alt="live_sach_logo"/>
        </div>
        <div className="header_nav">
          <Link className={`header_link ${ pathname === "/" ? "active_link" : "" }`} to="/">
            Home
          </Link>
          <a
            className="header_link"
            href="https://www.youtube.com/channel/UCphOeTv4sZQdtETQuIynSYw"
            rel="noreferrer"
            target="_blank"
          >
            Youtube Channel
          </a>
          <Link className={`header_link ${ pathname === "/contact" ? "active_link" : "" }`} to="/contact">
            Contact Us
          </Link>
        </div>
        <div className="menu_btn" onClick={toogleDrawer}>
          <i className="fad fa-bars fa-2x" />
        </div>
      </div>
      <Drawer open={drawerOpen} url = {pathname} />
    </div>
  );
};

const Drawer = ({ open, url }) => {
  const pathname = url;
  return (
    <div
      className="drawer_container"
      style={open ? { width: "100%" } : { width: 0 }}
    >
      <div className="drawer_wrapper">
        <div className="drawer_nav">
          <Link to="/" className={`drawer_link ${ pathname === "/" ? "drawer_active" : "" }`} >
            Home
          </Link>
          <a
              href="https://www.youtube.com/channel/UCphOeTv4sZQdtETQuIynSYw"
              className="drawer_link"
              rel="noreferrer"
              target="_blank"
          > Youtube Channel
          </a>
          <Link to='/contact'  className={`drawer_link ${ pathname === "/contact" ? "drawer_active" : "" }`}>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
