import Header from '../../components/header';
import Hero from '../../components/hero';
import "./index.scss";
import Main from '../../components/main';
import Perceptions from "../../components/perceptions";
import Footer from "../../components/footer";
import { withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img_kalamjit from '../../assets/images/kalamjit_kaur.jpeg';
import img_gurvinder from "../../assets/images/gurvinder_singh.jpeg";
import img_bakhshish from "../../assets/images/bakhshish_azaad.jpeg";
import img_jasveer from "../../assets/images/jasveer_singh.jpeg";
import img_raj_kumar from "../../assets/images/raj_kumar.jpeg";
import img_gurpreet from "../../assets/images/gurpreet.jpeg";



const team = [
    {
        name : "Bakhshish Singh",
        role : "Chief Editor",
        img : img_bakhshish
    },
    {
        name : "Kalamjit kaur",
        role : "Sub Editor",
        img : img_kalamjit
    },
    {
        name : "Jasvir Singh",
        role : "Manager",
        img : img_jasveer
    },
    {
        name : "Raj Kumar",
        role : "Reporter",
        img : img_raj_kumar
    },
    {
        name : "Gurpreet Singh",
        role : "Cameraman",
        img :img_gurpreet
    },
    {
        name : "Gurdvinder Singh",
        role : "Cameraman",
        img :img_gurvinder
    }
]



function Home(){

    return (
        <div>
            <Header />
            <Hero />
            <Perceptions />
            <Main />
            <span className="team_heading">Meet our team</span>
            <div className="container">
                <OwlCarousel
                    className = "owl-theme"
                    margin={8}
                    nav={false}
                    responsive={{
                        0:{
                            items:1
                        },
                        600:{
                            items:2
                        },
                        1000:{
                            items:3
                        }
                    }}

                >
                    {
                        team.map( (v,i) => {
                            return(
                                <div className="team_card" key={i}>
                                    <img alt={`team member ${v}`} src={v.img} />
                                    <div className="team_info">
                                        <h4>{v.name}</h4>
                                        <p>{v.role}</p>
                                    </div>
                                </div>
                            )
                        } )
                    }
                </OwlCarousel>
            </div>




            <Footer />
        </div>
    )
}

export default withRouter(Home);