import { BrowserRouter as Router, HashRouter, Route, Switch,  } from "react-router-dom";
import Home from './pages/home'
import "./index.css";
import Contact from "./pages/contact";

/*
    @author : Navraj Sandhu
    @modified : 15 Dec, 2020
*/

function App() {
  return (
    <div className="App">
        <Router>
          <HashRouter>
            <Switch>
              <Route path="/" exact> 
                 <Home />
              </Route>
              <Route path="/contact" exact>
                    <Contact />
              </Route>
            </Switch>
          </HashRouter>
        </Router>
    </div>
  );
}

export default App;
