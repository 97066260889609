import React from 'react';
import "./index.scss";

export default function Perceptions(){
    return(
        <div className="perception_container container">
            <h2>ਸਾਡੀਆਂ ਧਾਰਨਾਵਾਂ</h2>
           <p className="perception_para">
               ਉਹ ਮੈਟਰ ਪਾਇਆ ਜਾਵੇਗਾ: <br/>
               <li>1. ਜੋ ਲੋਕਤੰਤਰ ਦੀ ਭਾਵਨਾ ਨੂੰ ਮਜ਼ਬੂਤ ਕਰੇ।</li>
               <li> 2.  ਜਿਸ ਨਾਲ ਵਧੀਆ ਰਾਜ, ਵਧੀਆ ਸਭਿਆਚਾਰ ਤੇ ਵਧੀਆ  ਸ਼ਾਂਤੀ ਭਰਪੂਰ ਜਿੰਦਗੀ ਦੀ ਸਿਰਜਣਾ ਕਰਦਾ ਹੋਵੇ। ਸਮਾਜ ਅੰਦਰ ਫੈਲੀਆਂ
                   ਕੁਰੀਤੀਆਂ ਜਿਵੇਂ ਜਾਤੀ ਨਫ਼ਰਤ, ਅੰਧਵਿਸ਼ਵਾਸ, ਖੇਤਰਵਾਦ, ਰਿਸ਼ਵਤਖੋਰੀ, ਦਾਜ ਪ੍ਰਥਾ, ਬਾਲ ਵਿਆਹ,ਨਸ਼ੇ ਦੀ ਆਦਤ, ਧਾਰਮਿਕ
                   ਕੱਟੜਤਾ, ਅਨਿਆਂ ਆਦਿ ਨੂੰ ਦੂਰ ਕੀਤਾ
                   ਜਾ ਸਕੇ।</li>
               <li> 3.  ਜਿਸ ਨਾਲ ਲੋਕਾਂ ਦੀ ਰਾਜਨੀਤਕ, ਆਰਥਿਕ, ਸਮਾਜਿਕ, ਧਾਰਮਿਕ ਸਮਝ ਨੂੰ ਵਿਕਸਤ ਕੀਤਾ ਜਾ ਸਕੇ। ਇਸ ਸੰਬੰਧੀ ਲੋਕਾਂ ਨੂੰ ਜਾਗਰੂਕ
                   ਕਰਨ ਦਾ ਕੰਮ ਕੀਤਾ ਜਾਵੇਗਾ।</li>
               <li>  4. ਜਿਸ ਨਾਲ ਭਾਈਚਾਰਕ ਸਾਂਝ ਨੂੰ ਮਜ਼ਬੂਤ ਕੀਤਾ ਜਾ ਸਕੇ।</li>
               <li>  5. ਜਿਸ ਨਾਲ ਇਨਸਾਫ਼ ਅਤੇ ਨਿਆਂ ਦੀ ਭਾਵਨਾ ਨੂੰ ਮਜ਼ਬੂਤ ਕੀਤਾ ਜਾ ਸਕੇ।</li>
           </p>
        </div>
    )
}
