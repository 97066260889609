import Logo from '../../assets/images/logo.png';
import "./index.scss";

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="hero_wrapper">
        <div className="hero_slogan">
           <h1>ਜਾਣਕਾਰੀ ਜਿਸ 'ਤੇ ਤੁਸੀਂ  ਕਰ ਸਕਦੇ ਹੋ ਭਰੋਸਾ</h1>
        </div>
        <div className="hero_img">
            <img className="logo_0" alt="mic" src={Logo} />
        </div>
      </div>
    </div>
  );
};

export default Hero;

export const ContactHero = () => {
    return(
        <div className="contact_hero">
            <h2>Contact Us</h2>
        </div>
    )
}

