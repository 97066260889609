import React from "react";
import YouTube from "react-youtube";
import "./index.scss";



const Main = () => {
  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div className="container main_container">
      <div className="main_wrapper">
        <div className="row">
          <div className="col-lg-4 mb-2">
            <div className="yt_card">
              <iframe
                width="100%"
                height="250px"
                src="https://www.youtube.com/embed/4L_egi0eE1E"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-lg-4 mb-2">
            <div className="yt_card">
              <iframe
                width="100%"
                height="250px"
                src="https://www.youtube.com/embed/M3LY4V2Vs_o"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
          <div className="col-lg-4 mb-2">
            <div className="yt_card">
              <iframe
                width="100%"
                height="250px"
                src="https://www.youtube.com/embed/4y4khotZRMo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
          <div className="col-12 my-5 d-flex justify-content-center">
            <a target="_blank" rel='noreffer' href="https://www.youtube.com/channel/UCphOeTv4sZQdtETQuIynSYw" className="cla-btn">Visit our youtube channel</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;


