import React from 'react';
import { withRouter ,Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import './index.scss';


const Footer = ({ location }) => {
    const { pathname } = location;
    return (
        <div className="footer_container">
            <div className="footer_wrapper container">
                    <div className="header_logo">
                        <img alt="footer_logo" src={Logo} />
                    </div>
                    <div className="footer_social_links">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://m.facebook.com/Live-Sach-TV-119006996496169/"
                            className="fb">
                            <i className="fab fa-facebook"/>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/LiveSachTv"
                            className="tw"
                        >
                            <i className="fab fa-twitter" /></a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.youtube.com/channel/UCphOeTv4sZQdtETQuIynSYw"
                            className="yt">
                            <i className="fab fa-youtube" />
                        </a>
                    </div>
                   <div className="footer_links">
                       <Link
                           to="/"
                           className={pathname === "/" ? "footer_active" : ""} >
                           Home
                       </Link>
                       <a
                           target="_blank"
                           href="https://www.youtube.com/channel/UCphOeTv4sZQdtETQuIynSYw"
                           rel="noreferrer" >
                           Youtube
                       </a>
                       <Link
                           to="/contact"
                           className={pathname === "/contact" ? "footer_active" : ""}>
                           Contact
                       </Link>
                   </div>
            </div>
            <div className="copyright_section">
                <i className="fad fa-copyright ml-1" />   <span>2020 | All rights reserved | livesachtv.com</span>
            </div>
        </div>
    )
}

export default withRouter(Footer);